import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';

export default class EmailConfirmation extends React.Component {
  state = { emailIsVerified: false, error: false }

  componentDidMount() {
    this._isMounted = true

    const userId = window.location.hash.substring(1)

    fetch(`https://site-api.datocms.com/items/${userId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Api-Version': 2,
        Accept: 'application/json',
        Authorization: `Bearer 34791ab0b548177eb4d4df8bac9eda`,
      },
      body: JSON.stringify({
        data: {
          type: 'item',
          id: '187036',
          attributes: {
            emailisverified: true,
          },
        },
      }),
    })
      .then(response => {
        this.setState({ responseOk: response.ok })
        return response
      })
      .then(res => res.json())
      .then(data => {
        if (this._isMounted) {
          return this.state.responseOk
            ? this.setState({ emailIsVerified: true })
            : this.setState({ error: true })
        }
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Layout>
        <Seo title="Email is verifying" />
        <Page
          type={
            this.state.emailIsVerified
              ? 'success'
              : this.state.error
              ? 'error'
              : 'white'
          }
        >
          <Header closeUrl="/lessons">Email confirmation</Header>
          <Content center={true}>
            {this.state.emailIsVerified && (
              <h1 className="h2 growing text-transform-uppercase">
                email was successfully <br /> verified
              </h1>
            )}
            {this.state.error && <h1 className="h2 growing">User not found</h1>}
            {!this.state.emailIsVerified && !this.state.error && (
              <>
                <h1 className="h2 growing text-transform-uppercase">
                  Email is verifying
                </h1>
                <div className="page-text-container">
                  <p className="h6">please wait ...</p>
                </div>
              </>
            )}
          </Content>
          <Footer>
            {this.state.emailIsVerified && (
              <FooterLinkNext to="/login">go to log in</FooterLinkNext>
            )}
            {this.state.error && (
              <FooterLinkNext to="/">go to homepage</FooterLinkNext>
            )}
          </Footer>
        </Page>
      </Layout>
    )
  }
}
